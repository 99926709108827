"use client"
import React, { createContext, useContext, useState, ReactNode } from "react";

type BannerContextType = {
  bannerVisible: boolean;
  closeBanner: boolean;
  isMobile: boolean;
  toggleBanner: () => void;
  setBannerVisible:any;
  setIsMobile:any;
};
// Create the context
const BannerContext = createContext<BannerContextType | null>(null);

// Provide a hook for consuming the context
export const useBanner = () => {
  const context = useContext(BannerContext);
  if (!context) {
    throw new Error("useBanner must be used within a BannerProvider");
  }
  return context;
};
// Create the provider component
export const BannerProvider = ({ children }: { children: ReactNode }) => {
  const [bannerVisible, setBannerVisible] = useState(false);
  const [closeBanner, setCloseBanner] = useState(true);
  const [isMobile, setIsMobile] = useState(true)



  
  const toggleBanner = ()=> setCloseBanner(false)

  return (
    <BannerContext.Provider value={{ bannerVisible,closeBanner,isMobile,setIsMobile,setBannerVisible ,toggleBanner,}}>
      {children}
    </BannerContext.Provider>
  );
};
